import React from "react";
import styled from "styled-components";
import { TitleH1, StyledA } from "../components/common";
import SEO from "../components/SEO";

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 700px);
  align-items: center;
  justify-content: center;
  padding: 3.5rem 30px;
  @media (max-width: 1024px) {
    padding: 2rem 30px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 575px) {
    padding: 10px 30px;
  }
`;
const StyledUl = styled.ul`
  list-style-type: none;
`;
const StyledLi = styled.li`
  margin: 10px 0;
  font-size: 18px;
`;

const Sitemap = ({ location }) => {
  return (
    <>
      <SEO
        title="Sitemap - Receiptmakerly"
        description="Use the sitemap to find more resources about receiptmakerly.com. Learn about online receipt making, and how to use customizable templates to generate receipts."
        pathname={location.pathname && location.pathname}
        sofwareApp
      />
      <Container>
        <TitleH1>Receiptmakerly Site Map</TitleH1>
        <div></div>
        <h2>Uses</h2>
        <StyledUl>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/best-apps-for-receipt-scanning/"
            >
              5 Best Apps for Receipt Scanning for Small Businesses in 2022
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/business-tax-receipt/"
            >
              What is a Business Tax Receipt: Details with Examples
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/cash-receipts/"
            >
              Cash receipts
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/invoice-vs-receipt/"
            >
              Invoice vs receipt
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/itemized-receipt/"
            >
              Itemized receipt
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/organize-receipts/"
            >
              Organize receipts
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/printing-receipts/"
            >
              Printing receipts
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/rent-receipt/"
            >
              Rent receipt
            </StyledA>
          </StyledLi>

          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/amazon-style-receipts-generator/"
            >
              How to Generate Amazon Style Receipts & Invoices with
              Receiptmakerly
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/auto-repair-receipt/"
            >
              Auto Repair Receipt Templates and Receipt Maker
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/gas-fuel-petrol-receipts/"
            >
              How to Generate Gas Fuel and Petrol Receipts
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/grocery-store-receipts/"
            >
              Generate Grocery Store Receipts with Customizable Templates
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/hotel-receipts/"
            >
              How to Generate Hotel Receipts with Customizable Templates
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/invoicing-process/"
            >
              Best Tips for Flawless Invoicing Process
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/keep-the-receipts/"
            >
              Why You Must Keep the Receipts?
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/logo-for-invoice/"
            >
              Logo for Invoice: Reasons to Use It in Your Invoices
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/lyft-style-receipts-generator/"
            >
              How to Generate Lyft Style Receipts
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/ola-style-receipts-generator/"
            >
              Generate Ola Style Bill Receipts with a Few Simple Steps
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/paper-vs-online-invoice/"
            >
              Paper vs Online Receipts: Important Reasons to Use Invoice
              Generating Software
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/parking-receipts/"
            >
              Generate Parking Receipts with Customizable Templates
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/phone-and-internet-receipt/"
            >
              Generate Phone and Internet Receipts with Customizable Templates
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/restaurant-receipts/"
            >
              Generate Restaurant Receipts with Customizable Templates
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/taxi-receipts/"
            >
              How to Generate Taxi Receipt with Templates
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/uber-style-receipts-generator/"
            >
              How to Generate Uber Style Receipts
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/walgreens-style-receipts-generator/"
            >
              How to Generate Walgreens Style Pharmacy Receipts
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/walmart-style-receipts-generator/"
            >
              Create a Walmart Style Receipt with a Few Simple Steps
            </StyledA>
          </StyledLi>

          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/sales-receipt/"
            >
              Sales Receipt: All You Need to Know
            </StyledA>
          </StyledLi>

          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/business-receipts/"
            >
              Detail Guide to Business Receipts
            </StyledA>
          </StyledLi>

          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/payment-receipt/"
            >
              #1 Online Payment Receipt Maker
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/service-receipt/"
            >
              The Benefits of Service Receipt: A Comprehensive Guide
            </StyledA>
            <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/digital-receipt/"
            >
              Digital Receipt: All You Need to Know
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/e-receipt/"
            >
              In-Depth Guide to e Receipt and How to Generate It
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/expense-receipt/"
            >
              #1 Online Expense Receipt Maker App
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/car-repair-receipts/"
            >
              Car Repair Receipts:All You Need to Know
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/jewelry-receipt/"
            >
              What Is a Jewelry Receipt: Details with Templates
            </StyledA>
          </StyledLi>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/construction-receipt/"
            >
              Cyber Monday SaaS Deals
            </StyledA>
          </StyledLi>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/cyber-monday-saas-deals/"
            >
              #1 Online Construction Receipt Maker
            </StyledA>
          </StyledLi>
        </StyledUl>
        <h2>Blog</h2>
        <StyledUl>
          <StyledLi>
            <StyledA
              target="_blank"
              rel="noopener"
              href="https://receiptmakerly.com/blog/"
            >
              Receiptmakerly Blog
            </StyledA>
          </StyledLi>
        </StyledUl>
      </Container>
    </>
  );
};

export default Sitemap;
